import { render, staticRenderFns } from "./JuryFirstContent.vue?vue&type=template&id=3fda2bba&scoped=true&"
import script from "./JuryFirstContent.vue?vue&type=script&lang=js&"
export * from "./JuryFirstContent.vue?vue&type=script&lang=js&"
import style0 from "./JuryFirstContent.vue?vue&type=style&index=0&id=3fda2bba&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3fda2bba",
  null
  
)

export default component.exports