<template>
  <div id="Work-View">
		<div class="flex between">
			<div class="left-box " v-if="!loading">
				<div class="wrap" :class="{hasTitle}">
					<slot name="left-top"></slot>
					<div class="content ">
						<div class="title-h1 b">{{work.content.title}}</div>
						<!-- <div class="mt-20"></div>
						<div class="mt-10"></div> -->
						<div class="description" v-html="work.content.description"></div>
						<!-- <div class="mt-20"></div> -->
						<!-- <div class="mt-10"></div>
						<div class="description">{{work.content.edescription}}</div> -->
					</div>
					<slot name="left-bottom"></slot>
				</div>
				
				
			</div>
			<div class="right-box " >
				<slot name="right-top"></slot>
				<div v-if="!loading">
					<div class="title-h2">{{work.awardTypeEname}}</div>
					<div class="title-h1">{{L(work.awardTypeName)}}</div>
				</div>
				<div ref="sliderBox">
					<slider animation="normal" :height="height2" :control-btn="true" :autoplay="false" @change="sliderChange" v-if="!loading">
						
						<slider-item v-for="(item,i) in work.content.videos" :key="item.id" @click="showViewer(i)">
							<div :style="{height:height1}">
								<template v-if="item.type == 'image'">
									<CoverWrap border :src="item.path+'?x-oss-process=image/resize,w_1000'" :width="width" :height="height1"/>
								</template>
								<template v-else>
									<video width="100%" height="100%" controls="controls">
										<source :src="item.path" type="video/ogg">
										<source :src="item.path" type="video/mp4">
									</video>
								</template>
							</div>
						</slider-item>
						
						<slider-item v-for="(item,index) in work.content.images" :key="item.id" @click="showViewer(work.content.videos.length+index)">
							<CoverWrap border :src="item.path+'?x-oss-process=image/resize,w_1000'" :width="width" :height="height1"/>
							<!-- <div class="image-wrap"><img :src="item.path+'?x-oss-process=image/resize,l_1440'"/></div> -->
						</slider-item>
						
						<div class="swiper-pagination" slot="pagination"></div>
					</slider>
				</div>
				<slot name="right-bottom"></slot>
				
				<div class="mt-20"></div>
				<div class="mt-20"></div>
				<div class="mt-20"></div>
			</div>
		</div>
	</div>
</template>

<script>
import { Slider, SliderItem } from 'vue-easy-slider'
import CoverWrap from '/src/components/CoverWrap.vue';
export default {
  name: 'Work-View',
	components:{
		Slider, SliderItem,
		CoverWrap
	},
  props: {
    hasTitle:{
			default:false,
			type:Boolean
		}
  },
  data(){
    return {
			id:0,
			work:{
				
			},
			loading:true,
			width:0,
      height1:'100px',
      height2:'150px',
			
    }
  },
	watch:{
		async $route(){
			await this.initData();
		}
	},
	async mounted(){
		await this.initData();
		
		
	},
	
  methods:{
		async initData(){
			let sid = this.$route.params.sid;
			let id = this.$route.params.id;
			if(this.$root.md5(id) != sid){
				alert("非法操作");
				this.$root.toPage('/Index');
				return;
			}
			
			this.id = parseInt(id);
			
			this.loading = true;
			var width = parseInt(this.$refs.sliderBox.clientWidth);
			this.height1 = parseInt(726 / 1091 * width) + "px" ;
			this.height2 = parseInt(726 / 1091 * width) + 50 + "px" ;
			this.width = width + "px";
			let work = await this.request.get('/api/v1/awardWork/get?id='+this.id);
			if(work){
				this.$root.initWorkData(work);
				
				this.$set(this,'work',work);
			}
			
			this.loading = false;
			// this.$nextTick(()=>{
			// 	this.width = this.$refs.sliderBox.clientWidth;
			// })
		},
		
		showViewer(index){
			this.$root.showViewer((this.work.content.videos||[]).concat(this.work.content.images),index);
			// this.$root.showViewer(this.work.content.images.concat(this.work.content.videos),index);
		},
		sliderChange(index){
			// console.log(this.work.content.images[index].height);
			// this.height = this.work.content.images[index].height;
		},
		getData(){
			return this.$root.copy(this.work);
		},
		async initImage(item,index){
			let img = new Image()
			img.src = item.path
			img.onload =  async ()=> {
				if(img.width > this.width ){
					item.height = img.height / img.width * this.width;
				}
				console.log(img.width,img.height,index) // 打印图片信息
			}

		},
		imageLoad($event,item){
			console.log(item.height);
			this.height = item.height;
		}
	} 
}
</script>

<style scoped lang="less">
#Work-View{
	.left-box{
		// position: absolute;
		flex-shrink: 0;
		// left:45px;
		width:380px;
		.wrap{
			position: fixed;
			height:calc(100vh - 190px);
			overflow: hidden;
			// padding-right:20px;
			width:380px;
			max-width: 100%;
			
			.content{
				// height:calc(100% - 20px);
				height:100%;
				overflow: auto;
				padding-right:20px;
				.title{
					font-size:37px;
				}
				.description{
					font-size:16px;
					line-height: 1.4;
				}
			}
			&.hasTitle{
				.content{
					height:calc(100vh - 266px);
				}
			}
		}
		
		
	}
	.right-box{
		// position: fixed;
		// left:520px;
		// padding-left:435px; // 360+75
		// padding-right:15px;
		padding-bottom:20px;
		width: 100%;
		max-width: 850px;
		// width:100%;
		// height:calc(100vh - 180px);
		// overflow: auto;
		right:0;
		line-height: 1.2;
		.awardTypeEname{
			font-size:24px;
		}
		.awardTypeName{
			font-size:30px;
		}
		
		.image-wrap{
			text-align: center;
			height:100%;
			width:100%;
			img{
				max-width: 100%;
				max-height:100%;
			}
		}
		/deep/.slider-item{
			cursor: pointer;
		}
	}
}
</style>
