<template>
	<div id="Page-User-Work-View" class="main-wrap">
		<WorkView>
			
			<template v-slot:left-top>
				<!-- <div class="title-viewer" v-if="step==0">作品查看</div>
				<div class="title-viewer" v-if="step==1">编辑部初评</div>
				<div class="title-viewer" v-if="step==2">终评</div>
				<div class="mt-20"></div>
				<div class="mt-10"></div> -->
			</template>
			
			<!-- <template v-slot:left-bottom>
				left-bottom
			</template> -->
			
			<!-- <template v-slot:right-top>
				right-top
			</template> -->
			
			<!-- <template v-slot:right-bottom>
				right-bottom
			</template> -->
			
			
		</WorkView>
		
		
	</div>
  
</template>

<script>
import WorkView from '/src/components/WorkView.vue';
export default {
  name: 'Page-User-Work-View',
  components: {
		WorkView
  },
  data(){
    return {
			step:this.$route.params.step || 0,
			id: parseInt(this.$route.params.id) || 0,
			sid:this.$route.params.sid || 0,
    };
  },
  async mounted () {
		await this.initData();
		
  },
  methods: {
		async initData(){
			
		}
  }
}
</script>

<style scoped lang="less">
#Page-User-Work-View{
	.title-viewer{
		font-size:40.5px;
	}
}
</style>